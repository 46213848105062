<script>
import feathersClientUOA from "../plugins/feathers-client-uoa";

export default {
  async mounted() {
  
    console.log("Home")

    var user =  JSON.parse(localStorage.getItem("user"));
    
    const roleId = user.r_id;
    if (roleId === 0) {
      this.$router.push("/accessdenied");
    }
    if (roleId === 1) {
      this.$router.push("/manageowner");
    }
    if (roleId === 2) {
      this.$router.push("/booking");
    }
    if (roleId === 3) {
      this.$router.push("/realtime");
    }
    if (roleId === 4) {
      this.$router.push("/flight");
    }
    if (roleId === 21) {
      this.$router.push("/flight");
    }
    if (roleId === 5) {
      this.$router.push("/checkinactive");
    }
    if (roleId === 6) {
      this.$router.push("/realtime");
    }
    if (roleId === 7) {
      this.$router.push("/checkinactive");
    }
    if (roleId === 8) {
      this.$router.push("/transport");
    }
    if (roleId === 9) {
      this.$router.push("/flight");
    }
    if (roleId === 10) {
      this.$router.push("/realtime");
    }
    if (roleId === 11) {
      this.$router.push("/flight");
    }
    if (roleId === 12) {
      this.$router.push("/flight");
    }
    if (roleId === 13) {
      this.$router.push("/flightlog");
    }
    if (roleId === 14) {
      this.$router.push("/monthlychange");
    }
    if (roleId === 15) {
      this.$router.push("/summary");
    }
    if (roleId === 16) {
      this.$router.push("/mubadala");
    }
  }
};
</script>
  
  